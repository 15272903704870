






import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({})
export default class ZoomInControl extends Vue {
  protected handleClick(e: MouseEvent): void {
    this.$emit('click', e);
  }
}
