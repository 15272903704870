import { render, staticRenderFns } from "./CanvasToolbarTop.vue?vue&type=template&id=79ada8e1&scoped=true&"
import script from "./CanvasToolbarTop.vue?vue&type=script&lang=ts&"
export * from "./CanvasToolbarTop.vue?vue&type=script&lang=ts&"
import style0 from "./CanvasToolbarTop.vue?vue&type=style&index=0&id=79ada8e1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ada8e1",
  null
  
)

export default component.exports