





















import { Prop } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import LegendMove from '@/components/Editor/CanvasToolbar/LegendElements/LegendMoveViewport.vue';
import LegendMoveViewport from '@/components/Editor/CanvasToolbar/LegendElements/LegendMoveViewport.vue';
import LegendSingleSelect from '@/components/Editor/CanvasToolbar/LegendElements/LegendSingleSelect.vue';
import LegendMultiSelect from '@/components/Editor/CanvasToolbar/LegendElements/LegendMultiSelect.vue';
import LegendDeleteElement from '@/components/Editor/CanvasToolbar/LegendElements/LegendDeleteElement.vue';
import LegendNotEditable from '@/components/Editor/CanvasToolbar/LegendElements/LegendNotEditable.vue';
import ZoomInControl from '@/components/Editor/CanvasToolbar/ZoomControls/ZoomInControl.vue';
import ZoomOutControl from '@/components/Editor/CanvasToolbar/ZoomControls/ZoomOutControl.vue';
import { EditorZoom } from '@/mixins/EditorMixins';

@Component({
  components: {
    ZoomOutControl,
    ZoomInControl,
    LegendNotEditable,
    LegendDeleteElement,
    LegendMultiSelect,
    LegendSingleSelect,
    LegendMoveViewport,
    LegendMove,
  },
})
export default class CanvasToolbarTop extends mixins(EditorZoom) {
  @Prop({
    default: true,
    required: false,
  })
  protected showMoveViewportLegend?: boolean;

  @Prop({
    default: true,
    required: false,
  })
  protected showSingleSelectLegend?: boolean;

  @Prop({
    default: true,
    required: false,
  })
  protected showMultiSelectLegend?: boolean;

  @Prop({
    default: true,
    required: false,
  })
  protected showDeleteElementLegend?: boolean;

  @Prop({
    default: false,
    required: false,
  })
  protected showNotEditableLegend?: boolean;

  @Prop({
    default: true,
    required: false,
  })
  protected showZoomInControl?: boolean;

  @Prop({
    default: true,
    required: false,
  })
  protected showZoomOutControl?: boolean;

  protected handleZoomInClick(): void {
    this.setZoom(Math.max(this.currentZoom - 0.1, 0.1));
  }

  protected handleZoomOutClick(): void {
    this.setZoom(this.currentZoom + 0.1);
  }
}
