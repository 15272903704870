import Point from '@/models/Point';

export default class ModelBackgroundImage {
  private _href: string;
  private _startPos: Point;

  constructor(href: string, startPos: Point) {
    this._href = href;
    this._startPos = startPos;
  }

  get href(): string {
    return this._href;
  }

  get startPos(): Point {
    return this._startPos;
  }

  set startPos(value: Point) {
    this._startPos = value;
  }
}
