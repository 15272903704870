




























































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import { State } from 'vuex-class';
import SelectedModelElement from '@/models/SelectedModelElement';
import moment from 'moment';
import Attribute from '@/models/Attribute';
import ModelElement from '@/models/ModelElement';
import TextAnnotationDrawable from '@/models/drawables/TextAnnotationDrawable';
import ModelConfig from '@/models/ModelConfig';
import ModelElementType from '@/models/ModelElementType';
import Node from '@/models/Node';
import Edge from '@/models/Edge';
import AttributeType from '@/models/AttributeType';
import ModelScope from '@/models/ModelScope';
import MarkerDrawable from '@/models/drawables/MarkerDrawable';
import Marker from '@/models/Marker';
import Model from '@/models/Model';
import Point from '@/models/Point';
import { Toasts } from '@/mixins/ToastMixins';
import { mixins } from 'vue-class-component';
import MarkerDrawableHelper from '@/models/drawables/MarkerDrawableHelper';

@Component
export default class AttributeBar extends mixins(Toasts, Vue) {
  @State('currentModel', { namespace: 'modelEditor' })
  protected currentModel!: Model;

  @State('currentConfig', { namespace: 'modelEditor' })
  protected currentConfig!: ModelConfig;

  @State('selectedElements', { namespace: 'modelEditor' })
  protected selectedElements!: SelectedModelElement[];

  @Prop({
    required: false,
    default: false,
  })
  protected readOnly!: boolean;

  @Prop({
    required: false,
    default: undefined,
  })
  protected modelScope!: ModelScope;

  @Prop({ required: true, default: false })
  protected isStandalone!: boolean;

  protected selectedMarkerType = '';
  protected selectedMarkerState = '';

  protected format(date: Date): string {
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  protected createMarker(event: Event): void {
    event.preventDefault();
    if (this.selectedMarkerType.length > 0 && this.selectedMarkerState.length > 0) {
      this.$nextTick(() => {
        const newPosition = MarkerDrawableHelper.calculatePositionOfMarker(
          this.selectedElements[0].modelElement,
          this.currentConfig,
          this.getMarkerTypesIndexOfConfig(this.selectedMarkerType)
        );
        if (newPosition) {
          const marker = new Marker(
            uuidv4(),
            this.selectedMarkerType,
            this.selectedMarkerType,
            '',
            this.selectedMarkerState,
            null,
            newPosition
          );
          this.selectedElements[0].modelElement.markers.push(marker);
          this.$root.$emit('reload-drawables');
          this.$bvModal.hide('modal-create-marker');
        } else {
          this.showToast('Failed to calculate new position of marker', '', 'danger');
        }
      });
    } else {
      this.showToast('Failed to add marker', 'Define markerType and markerState before adding.', 'danger');
    }
  }

  protected resetMarkerPositions(element: ModelElement): void {
    if (!element) {
      element = this.selectedElements[0].modelElement;
    }
    element.markers.forEach((marker) => {
      const resetPosition = MarkerDrawableHelper.calculatePositionOfMarker(
        element,
        this.currentConfig,
        this.getMarkerTypesIndexOfConfig(marker.type)
      );
      const existingDrawable = this.currentModel.drawables.find((drawable) => {
        return (drawable as MarkerDrawable).marker === marker;
      });
      if (existingDrawable instanceof MarkerDrawable && resetPosition) {
        existingDrawable.startPos = resetPosition.startPos;
      }
    });
    this.$root.$emit('reload-drawables');
  }

  protected resetCreateMarker(): void {
    this.selectedMarkerType = '';
    this.selectedMarkerState = '';
  }

  protected deleteMarker(marker: Marker): void {
    this.currentModel.drawables.filter((drawable) => {
      let drawables = this.currentModel.drawables;
      // Check if drawable is a Marker(Drawable)
      if (drawable instanceof MarkerDrawable) {
        // Check if drawable contains the marker to be deleted
        if (drawable.marker === marker) {
          // if marker is part of drawable
          // remove marker from model element
          // Delete from list of markers
          drawable.belongsTo.markers.splice(drawable.belongsTo.markers.indexOf(drawable.marker), 1);
          // Delete from list of drawables to avoid drawing
          drawables = this.currentModel.drawables.splice(this.currentModel.drawables.indexOf(drawable), 1);
        }
      }
      return drawables;
    });
    this.$root.$emit('reload-drawables');
  }

  protected filterAttributes(element: ModelElement): Attribute[] {
    return element.attributes.filter((attribute) => {
      const hasWellKnownType = ['height', 'width', 'internal_save_id'].indexOf(attribute.name.toLowerCase()) !== -1;
      const hasConfigType = this.getTypeOfAttribute(attribute.name, element) !== undefined;

      if (!hasWellKnownType && !hasConfigType) {
        console.warn("Skipping attribute with unknown AttributeType '" + attribute.name + "' on ModelElement", element);
      }
      return hasConfigType;
    });
  }

  protected changeAttribute(element: ModelElement, attribute: Attribute, target: HTMLInputElement): void {
    const attributeType = this.getTypeOfAttribute(attribute.name, element);
    if (((target.value && target.value.length > 0) || attributeType?.nullable) && target.value.length < 5000) {
      if (target.value !== attribute.value) {
        attribute.value = target.value;
      }
    } else {
      this.$bvToast.toast(
        attribute.name + ' is ' + (attributeType?.nullable ? 'not ' : '') + 'required (max. Length 5000).',
        {
          title: 'Update failed',
          variant: 'danger',
          solid: true,
          autoHideDelay: 3000,
        }
      );
      target.checkValidity();
    }
  }

  protected isTextAnnotationDrawableOrMarkerDrawable(element: SelectedModelElement): boolean {
    return element.modelElement instanceof TextAnnotationDrawable || element.modelElement instanceof MarkerDrawable;
  }

  protected isMarkerDrawable(element: SelectedModelElement): boolean {
    return element.modelElement instanceof MarkerDrawable;
  }

  protected isTextAnnotationDrawable(element: SelectedModelElement): boolean {
    return element.modelElement instanceof TextAnnotationDrawable;
  }

  protected getTypeOfAttribute(name: string, modelElement: ModelElement): AttributeType | undefined {
    let type: ModelElementType | undefined = undefined;
    if (this.currentConfig && modelElement instanceof Node) {
      type = this.currentConfig.getNodeType(modelElement.type);
    } else if (this.currentConfig && modelElement instanceof Edge) {
      type = this.currentConfig.getEdgeType(modelElement.type);
    }

    if (type) {
      return type.attributeTypes.find((attributeType) => attributeType.variableName === name);
    }
  }

  protected getMarkerStatesOfMarker(type: string): string[] | undefined {
    const markerType = this.currentConfig.getMarkerType(type);
    return markerType?.markerStates.map((markerState) => markerState.stateName);
  }

  protected getUnusedMarkerTypesOfConfigForNode(): string[] | undefined {
    if (this.selectedElements.length > 0) {
      const usedMarkerTypeNames = this.selectedElements[0].modelElement.markers.map((it) => it.type);
      return this.getMarkerTypesOfConfig()?.filter((markerType) => !usedMarkerTypeNames.includes(markerType));
    }

    return undefined;
  }

  protected getMarkerTypesOfConfig(): string[] | undefined {
    const markerTypes = this.currentConfig.markerTypes;
    return markerTypes.map((markerType) => markerType.typeName);
  }

  protected getMarkerTypesIndexOfConfig(type: string): number | undefined {
    const markerType = this.currentConfig.getMarkerType(type);
    if (markerType) {
      return this.currentConfig.markerTypes.indexOf(markerType);
    }
  }

  protected centerPositionOfEdge(modelElement: ModelElement): Point {
    let xStartPoint = modelElement.endPos.x;
    let xDiff = modelElement.endPos.x - modelElement.startPos.x;
    if (modelElement.startPos.x > modelElement.endPos.x) {
      xStartPoint = modelElement.startPos.x;
      xDiff = modelElement.startPos.x - modelElement.endPos.x;
    }
    let yStartPoint = modelElement.endPos.y;
    let yDiff = modelElement.endPos.y - modelElement.startPos.y;
    if (modelElement.startPos.y > modelElement.endPos.y) {
      yStartPoint = modelElement.startPos.y;
      yDiff = modelElement.startPos.y - modelElement.endPos.y;
    }
    if (modelElement) {
      if (this.isEdge(modelElement)) {
        return new Point(xStartPoint - xDiff / 2, yStartPoint - yDiff / 2);
      }
    }

    return new Point(0, 0);
  }

  protected isEdge(modelElement: ModelElement): boolean {
    return modelElement instanceof Edge;
  }
}
